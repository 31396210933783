import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import * as API from "./../../../Endpoint/endpoint";

const MemberTable = () => {
    const [member, setMember] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        axios({
            url: API.GET_MEMBERS_API,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setMember(res.data);
        }).catch((err) => {
            alert("Failure: " + err);
        });
    };

    const DeleteMember = (id) => {
        axios({
            url: API.DELETE_MEMBERS_API(id),
            method: 'DELETE',
        }).then((res) => {
            alert("Deleted member successfully");
            getData();
        }).catch((err) => {
            alert("Error: " + err);
        });
    };

    const navigate = useNavigate();

    const ViewMember = (id) => {
        localStorage.setItem("member_id", id);
        navigate("/admin/viewmember");
    };

    const AddMember = () => {
        navigate("/admin/addmember");
    };

    const UpdateMember = (id) => {
        localStorage.setItem("member_id", id);
        navigate("/admin/updatemember");
    };

    return (
        <div className='bg-[#B5B5B5] h-[auto] pb-20 pt-10'>
            <div className='p-4'>
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-5 rounded-full flex justify-center items-center mx-auto" onClick={AddMember}>
                    Create Member
                </button>
            </div>
            <div className="max-w-4xl mx-auto pt-[30px]">
                <div className="flex flex-col">
                    <div className="overflow-x-auto shadow-md sm:rounded-lg">
                        <div className="inline-block min-w-full align-middle">
                            <div className="overflow-hidden">
                                <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700">
                                    <thead className="bg-[#7f7ff5] dark:bg-[#8b8bf7]">
                                        <tr>
                                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-center text-gray-700 uppercase dark:text-gray-400">
                                                Id
                                            </th>
                                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-center text-gray-700 uppercase dark:text-gray-400">
                                                Member Name
                                            </th>
                                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-center text-gray-700 uppercase dark:text-gray-400">
                                                Phone Number
                                            </th>
                                            <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-center text-gray-700 uppercase dark:text-gray-400">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                                        {
                                            member.map((d, k) => (
                                                <tr className="hover:bg-gray-100 dark:hover:bg-gray-700" key={k}>
                                                    <td className="py-3 px-6 text-xs font-medium tracking-wider text-center text-gray-700 uppercase dark:text-gray-400">{d.id}</td>
                                                    <td className="py-3 px-6 text-xs font-medium tracking-wider text-center text-gray-700 uppercase dark:text-gray-400">{d.member_name}</td>
                                                    <td className="py-3 px-6 text-xs font-medium tracking-wider text-center text-gray-700 uppercase dark:text-gray-400">{d.member_phone}</td>
                                                    <td className="py-3 px-6 text-xs font-medium tracking-wider text-center text-gray-700 uppercase dark:text-gray-400">
                                                        <div className='inline pr-[3px]'>
                                                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-5 rounded-full" onClick={() => ViewMember(d.id)}>
                                                                View
                                                            </button>
                                                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full ml-1" onClick={() => DeleteMember(d.id)}>
                                                                Delete
                                                            </button>
                                                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full ml-1" onClick={() => UpdateMember(d.id)}>
                                                                Edit
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MemberTable;
