
import MainCommon from "./MainCommon/MainCommon";
function App() {
  return (
    <>
      <div className="font-semibold text-Manrope bg-gray-100 h-[auto] capitalize">
        <MainCommon/>
      </div>
    </>

  );
}

export default App;
